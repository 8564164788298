<template>
  <v-container fluid class="pt-0">
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-center justify-center color1 color1Text--text">
            <h5>New {{level ? `${level.title.replace(' Membership', '')} ` : ''}}Member Registration</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row dense>
              <!-- PHOTO -->
              <v-col cols="12" md="3">
                  <v-img
                    :src="pic"
                    max-height="100%"
                    aspect-ratio="1"
                  >
                    <v-fab-transition>
                      <v-btn
                        color="error white--text"
                        class="float-right"
                        fab small
                        @click.stop="unsavedImage = null"
                        v-if="unsavedImage"
                      >
                        <v-icon>fas fa-times</v-icon>
                      </v-btn>
                    </v-fab-transition>
                    <v-expand-transition>
                        <div class="d-flex v-card--reveal color3 color3Text--text clickable" v-if="editing && !unsavedImage" style="height: 50%">
                          <image-input v-model="unsavedImage" ref="uploader">
                            <v-container class="fill-height">
                              <v-row dense>
                                <v-col class="text-center" cols="12" >
                                  <v-icon color="color3Text" large>fas fa-camera</v-icon>
                                  <div>{{player.profilePic ? 'Update' : 'Upload a profile picture'}}</div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </image-input>
                        </div>
                    </v-expand-transition>
                  </v-img>
              </v-col>
              <!-- TOP PERSON FORM -->
              <v-col cols="12" md="9">
                <v-card flat outline>
                  <person-form
                    ref="p1"
                    :player="player"
                    :disable="disabled"
                    @valid-change="onValidChange1"
                    :fields="['name', 'gender', ...fields.top.ask]"
                    :requiredFields="fields.top.req"
                    :allowInternational="true"
                    :validate="terms || e"
                  ></person-form>
                </v-card>
              </v-col>
              <!-- BOTTOM PERSON FORM -->
              <v-col cols="12" v-if="fields.bottom.req.length || fields.bottom.ask.length">
                <v-card flat outline>
                  <person-form
                    ref="p2"
                    :player="player"
                    :disable="disabled"
                    @valid-change="onValidChange2"
                    :fields="fields.bottom.ask"
                    :requiredFields="fields.bottom.req"
                    :validate="terms || e"
                  ></person-form>
                </v-card>
              </v-col>
            </v-row>
            <v-expand-transition>
              <div v-if="isJunior && (fields.bottom.req.includes('parent') || fields.bottom.req.includes('emergency'))">
                <v-row dense>
                  <v-col cols="12" v-if="fields.bottom.req.includes('parent')">
                    <v-card flat outline>
                      <person-form
                        ref="p3"
                        :player="player.parent"
                        :disable="disabled"
                        @valid-change="onValidChange3"
                        :fields="['name']"
                        :requiredFields="['phone', 'email']"
                        :validate="terms || e"
                      ></person-form>
                    </v-card>
                  </v-col>
                  <v-col cols="12" v-if="fields.bottom.req.includes('emergency')">
                    <v-card flat outline>
                      <person-form
                        ref="p4"
                        :player="player.emergency"
                        :disable="disabled"
                        @valid-change="onValidChange4"
                        :fields="['name']"
                        :requiredFields="['phone', 'email']"
                        :validate="terms || e"
                      ></person-form>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
            <v-expand-transition>
              <div v-if="editing && waivers.length">
                <v-row
                  dense
                  justify="end"
                  v-for="w in waivers"
                  :key="w.label"
                >
                  <v-checkbox
                    v-model="agreed"
                    :value="w.url"
                    hide-details
                    color="success"
                  >
                    <template v-slot:label>
                      <div>I have read and agree to the&nbsp;
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a
                              target="_blank"
                              :href="w.url"
                              @click.stop
                              v-on="on"
                              class="color3--text" style="text-decoration: none"
                            >
                              {{w.label}}
                            </a>
                          </template>
                          Opens in new window
                        </v-tooltip>
                      </div>
                    </template>
                  </v-checkbox>
                </v-row>
              </div>
            </v-expand-transition>
            <v-alert
              type="error"
              :value="showAgeAlert"
              transition="scale-transition"
              prominent text
              border="left"
            >
              You must be {{maxAge}} or younger as of {{maxAgeAsOf}}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-expand-transition>
      <div v-if="e">
        <v-row dense>
          <v-col class="text-right" cols="12" >
            Oops, there seems to be some errors. Please correct them before continuing.
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
    <!-- FABS -->
    <v-row dense>
      <v-col cols="6">
        <!-- CANCEL -->
        <v-fab-transition>
          <v-btn
            color="color3Text color3--text"
            fab
            @click.stop="cancel"
          >
            <v-icon>fas fa-caret-left</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-col>
      <v-col class="text-right" cols="6" >
        <v-fab-transition>
          <v-btn
            v-if="fab && fab.text"
            :color="fab.color"
            flat
            :key="fab.key"
            @click.stop="fab.click"
            :disabled="fab.disabled"
          >
            {{fab.text}}
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            :dark="program.id === 1"
            v-if="fab"
            :color="fab.color"
            fab
            :key="fab.key"
            @click.stop="fab.click"
            :loading="fab.loading"
            :disabled="fab.disabled"
          >
            <v-icon>{{fab.icon}}</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-col>
    </v-row>
    <!-- WELCOME DIALOG -->
    <v-dialog
      v-model="welcome"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title>Welcome to {{program.organization.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="color2 color2Text--text" fab small @click.stop="welcome = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="subheading">
          <p>You have successfully registered for your {{program.organization.name}} membership.</p>
          <p class="font-weight-bold">Your Member Id is - {{player.id}}</p>
          <p>You should be recieving a confirmation email any minute now.</p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DUPLICATE DIALOG -->
    <v-dialog
      v-model="dup"
      v-if="error"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="text-center" :class="{p1440Back: program === 1}">
        <v-toolbar color="white--text" flat style="background: none;">
          <v-toolbar-title>Oops!</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="white" icon text small @click.stop="dup = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="subheading">
          <p class="title">It looks like there is already a membership for {{player.fullname}}</p>
          <p class="font-weight-bold">{{this.player.male ? 'His' : 'Her'}} id is - {{error.id}}</p>
          <p>
              <v-btn
                color="color3 color3Text--text"
                @click.stop="addToExisting(error.member)"
              >Use {{error.id}}</v-btn></p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snack"
      vertical
      color="success"
    >
      Save Successful!
      <v-btn text color="white" @click.native="snack = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import PersonForm from '@/components/Forms/PersonForm'
import PlayerProfile from '@/classes/PlayerProfile'
import ImageInput from '@/components/Utils/ImageUploaderNew.vue'
import moment from 'moment'

export default {
  props: ['program'],
  data () {
    return {
      player: new PlayerProfile(),
      editing: true,
      loading: false,
      valid1: false,
      valid2: false,
      valid3: false,
      valid4: false,
      selectedLevel: null,
      welcome: false,
      dup: false,
      snack: false,
      error: null,
      unsavedImage: null,
      uploading: false,
      terms: false,
      agreed: [],
      e: false,
      level: null
    }
  },
  computed: {
    fields () {
      return (this.jProps && this.jProps.fields) || {
        top: {
          req: ['phone', 'email', 'fullAddress'],
          ask: []
        },
        bottom: {
          req: ['dob', 'gradyear', 'password', 'club', 'parent', 'emergency'],
          ask: ['shirtSize', 'height', 'highSchool', 'instagram', 'twitter']
        }
      }
    },
    jProps () {
      return this.level && this.level.jsonProps && JSON.parse(this.level.jsonProps)
    },
    maxAge () {
      return (this.jProps && this.jProps.maxAge) || 1000
    },
    maxAgeAsOf () {
      const base = ((this.jProps && this.jProps.maxAgeAsOf) || '1/1/YYYY').replace('YYYY', moment().format('YYYY'))
      return base
    },
    ageAsOf () {
      return this.player && this.player.dob ? Math.floor(moment(this.maxAgeAsOf).diff(moment(this.player.dob), 'years', true)) : 1000
    },
    validAge () {
      return this.ageAsOf > 0 && this.ageAsOf <= this.maxAge
    },
    showAgeAlert () {
      return this.maxAge < 1000 && (this.player && this.player.dob) && !this.validAge
    },
    waivers () {
      return (this.jProps && this.jProps.waivers) || []
    },
    agreedToAll () {
      const a = this.agreed
      a.sort()
      return !this.waivers || JSON.stringify(a) === JSON.stringify(this.waivers.map(m => m.url).sort())
    },
    dirty () {
      return this.editable && JSON.stringify(this.editable.dto) !== JSON.stringify(this.player.dto)
    },
    dirty2 () {
      return this.dirty || !!this.unsavedImage
    },
    saveable () {
      return this.allValid && this.agreedToAll
    },
    allValid () {
      return this.valid1 &&
        (this.valid2 || this.fields.bottom.req.length + this.fields.bottom.ask.length === 0) &&
        (this.valid3 || !this.isJunior || !this.fields.bottom.req.includes('parent')) &&
        (this.valid4 || !this.isJunior || !this.fields.bottom.req.includes('emergency')) &&
        !this.showAgeAlert
    },
    age () {
      return this.player.age
    },
    isJunior () {
      return this.age && this.age <= 19
    },
    leftFab () {
      return [1, 2, 3].includes(this.window) ? {
        color: 'color3Text color3--text',
        icon: 'fas fa-caret-left',
        click: () => { this.window-- }
      } : null
    },
    fab () {
      return {
        key: 'save',
        color: 'color3 color3Text--text',
        icon: 'fas fa-caret-right',
        click: this.onSaveClick,
        loading: this.loading,
        disabled: !this.saveable
      }
    },
    disabled () {
      return !this.editing
    },
    pic () {
      return this.unsavedImage ? this.unsavedImage.imageURL : this.player.profilePic || (this.player.male ? 'https://vblimages.blob.core.windows.net/images/avatar-male.jpg' : 'https://vblimages.blob.core.windows.net/images/avatar-female.jpg')
    }
  },
  methods: {
    cancel () {
      this.$router.push({ name: 'member-levels' })
    },
    onValidChange1 (val) {
      this.valid1 = val
    },
    onValidChange2 (val) {
      this.valid2 = val
    },
    onValidChange3 (val) {
      this.valid3 = val
    },
    onValidChange4 (val) {
      this.valid4 = val
    },
    onSaveClick () {
      if (!this.saveable) {
        this.e = true
        return
      }
      this.loading = true
      const dto = this.player.dto
      const image = this.unsavedImage ? this.unsavedImage.formData : null
      this.$VBL.organization.members.post(this.program.organization.username, dto, image)
        .then(r => {
          this.player.update(r.data)
          this.addToCart()
        })
        .catch(e => {
          console.log(e.response)
          if (e.response.data && e.response.data.error === 1) {
            this.error = e.response.data
            this.dup = true
          }
        })
        .finally(() => { this.loading = false })
    },
    addToCart () {
      this.$router.push({ name: 'member-levels', params: { profile: this.player, level: this.level, step: 'addToCart' } })
    },
    addToExisting (profile) {
      this.$router.push({ name: 'member-levels', params: { profile: profile, level: this.level, step: 'addToCart' } })
    }
  },
  watch: {
    agreedToAll: function (v) {
      if (v && !this.e) this.e = true
    },
    saveable: function (v) {
      if (v && this.e) this.e = false
    }
  },
  created () {
    this.level = this.$route.params.level
    !this.level && this.cancel()
  },
  components: {
    PersonForm,
    ImageInput
  }
}
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
.v-card--overlay {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
